import React from 'react';
import { Grid, TextField, withStyles, InputAdornment, InputLabel } from '@material-ui/core';
import styles from './styles';

type Props = { classes: any, label: string, name: string, type?: string, placeholder?: string, disabled?: boolean, value?: any, onChange?: (event: any) => void };

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#707070',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0000003b',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E6E6E6',
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: '#0000003b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000003b',
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontSize: 14,
      }
    }
  },
})(TextField);

function TextFieldSimpleRegister(props: Props) {
  const { classes, label, name, type, disabled, placeholder, value, onChange } = props;

  return (
    <Grid item xs={12}>
      <InputLabel shrink className={classes.label}>{label}</InputLabel>
      <CssTextField
        required
        fullWidth
        variant="outlined"
        name={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        InputLabelProps={{
          style: { color: '#707070', fontWeight: 'bold' },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ color: "#707070" }} />
          ),
        }}
      />
    </Grid>
  )
}

export default withStyles(styles)(TextFieldSimpleRegister);