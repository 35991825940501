const styles = {
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    backgroundImage: 'url(/assets/bg_home.png)',
    backgroundSize: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  logoSfc: {
    maxWidth: '200px',
    width: '100%',
    marginBottom: 15,
  },
  pageTitle: {
    color: '#0A4F9D',
    fontSize: '2rem',
  },
  pageTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageQrcodeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataContainer: {
    height: 'fit-content',
    marginTop: '2rem',
    padding: '1rem',
    border: '1px solid #707070',
    borderRadius: '10px',
  },
  qrcodeContainer: {
    marginTop: '1rem',
  },
  qrcodeGridCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  qrcodeImg: {
    width: '14rem',
  },
  btnDownload: {
    width: '100%',
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#0A4F9D',
    color: '#fff',
    transition: '.3s',
    '&:hover': {
      backgroundColor: '#0285B5',
    },
  },
  landingImg: {
    width: '70%',
  },
  textMiddle: {
    margin: '1rem 0rem',
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
  },
};

export default styles;
