/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import {
  ColDef,
  DataGrid,
  CellParams,
  GridOverlay,
} from '@material-ui/data-grid';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import { EditOutlined } from '@material-ui/icons';

import { ResidentHomeEntity } from '../../../../../../interfaces/entities/resident-home.entity';

import styles from './styles';

interface FamilyProps {
  classes: any;
  homeId: number;
  reloading: boolean;
  handleToggleRefresh(): void;
  family: ResidentHomeEntity[];
  benefitGrantedId?: string,
  reloadFamily(): Promise<void>;
}

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const FamilyTable: React.FC<FamilyProps> = (props :any) => {
  const {
    family,
    homeId,
    classes,
    reloading,
    benefitGrantedId,
  } = props;
  const history = useHistory();
  const { url } = useRouteMatch();
  const dateFns = new DateFnsUtils();

  function navigateToCitizenEdit(citizenId: number) {
    let returnPath = url;

    if (benefitGrantedId) {
      returnPath += `?benefitGrantedId=${benefitGrantedId}`;
    }

    history.push(`/app/cidadao/${citizenId}/editar?houseId=${homeId}&returnPath=${returnPath}`);
  }

  function navigateToManageIncome(residentId: number) {
    let returnPath = url;

    if (benefitGrantedId) {
      returnPath += `?benefitGrantedId=${benefitGrantedId}`;
    }

    history.push(`/app/renda/gerenciar?homeId=${homeId}&residentId=${residentId}&returnPath=${returnPath}`);
  }

  const columnWidth = 170;
  const columns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: columnWidth,
    },
    {
      field: 'birthdate',
      headerName: 'Data de nascimento',
      valueFormatter: ({ value }: CellParams) => !!value ? dateFns.format(new Date(value as string), 'dd/MM/yyyy') : 'Sem registro',
      width: columnWidth,
    },
    {
      field: 'birth_certificate',
      headerName: 'Certidão de Nascimento',
      width: columnWidth,
    },
    {
      field: 'responsible_kinship',
      headerName: 'Grau de Parentesco',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'telephone',
      headerName: 'Telefone',
      width: columnWidth,
    },
    {
      field: 'responsible',
      headerName: 'É responsável',
      valueFormatter: ({ value }: CellParams) => {
        return (value as boolean) ? 'Sim' : 'Não';
      },
      width: columnWidth - 10,
    },
    {
      field: 'bpc',
      headerName: 'BPC',
      valueFormatter: ({ value }: CellParams) => {
        return (value as boolean) ? 'Sim' : 'Não';
      },
      width: columnWidth - 70,
    },
    {
      field: 'total_income',
      headerName: 'Renda total',
      valueFormatter: ({ value }: CellParams) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(value as number);
      },
      width: columnWidth,
    },
    {
      field: 'id',
      headerName: 'Editar',
      renderCell: ({ value }: CellParams) => (
        <Tooltip title="Editar cidadão">
          <IconButton onClick={() => navigateToCitizenEdit(value as number)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      ),
      width: columnWidth - 50,
    },
    {
      field: 'incomes',
      headerName: ' ',
      renderCell: ({ value, row }: CellParams) => renderVisibilityIcon(row as ResidentHomeEntity),
      width: columnWidth + 40,
    },
  ];

  function renderVisibilityIcon(resident: ResidentHomeEntity) {
    
    return (
      <Button color="primary" variant="outlined" onClick={() => navigateToManageIncome(resident.id)}>
        Gerenciar renda
      </Button>
    );
  }

  return (
    <>
      <Box>
        <Box className={classes.tableContainer}>
          <DataGrid
            disableSelectionOnClick
            columns={columns}
            loading={reloading}
            rows={family}
            autoHeight
            localeText={{
              footerTotalRows: 'de',
              footerPaginationRowsPerPage: 'Residentes por página'
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default withStyles(styles)(FamilyTable);
