const styles: any = {
  pageContainer: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    backgroundImage: 'url(/assets/bg_home.png)',
    backgroundSize: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoSfc: {
    maxWidth: '160px',
    width: '100%',
    marginBottom: 15,
  },
  pageTitle: {
    margin: '10px 30px 50px',
    fontWeight: '600',
    fontSize: 22,
    color: '#0A4F9D',
    textAlign: 'center'
  },
  logoAcelera: {
    maxWidth: '150px',
    width: '100%',
    marginTop: 60,
  },
};

export default styles;