import React, { useState } from 'react';
import * as Yup from 'yup';

import { Grid, Typography, withStyles } from '@material-ui/core';

import withSnackbar from '../../../templates/WithSnackbar';

import BenefitFishService from '../../../../services/BenefitFishService';

import { messages } from '../../../../constants/messages';

import ButtonMain from '../../../templates/ButtonMain';
import TextFieldSimpleRegister from '../../../templates/TextFieldSimpleRegister';
import styles from './styles';
import Beneficiary from '../../../../model/entities/Beneficiary';

type Props = {
  auth: any,
  classes: any,
  history: any,
  login: Function,
  openSnackbar: any,
  onConsultBeneficiary(beneficiary: Beneficiary | null): void;
  handleNotFoundBeneficiary(): void;
};

const FishConsult: React.FC<Props> = (props) => {
  const { classes, openSnackbar, onConsultBeneficiary, handleNotFoundBeneficiary } = props;

  const [cpf, setCpf] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function handleError(e: any) {
    openSnackbar('error', e);
  }

  const getCitizen = async (cpf: string) => {
    const benefitData = await BenefitFishService.consult(cpf);
    const citizen = {
      ...benefitData.citizen,
      benefit: { ...benefitData.benefit }
    };
    if (citizen) {
      return citizen as unknown as Beneficiary;
    }

    return null;
  }

  async function onSubmit() {
    try {
      setLoading(true);

      const schema = Yup.object().shape({ cpf: Yup.string().required(messages.emptyFields) });
      await schema.validate({ cpf });

      const beneficiary = await getCitizen(cpf);

      onConsultBeneficiary(beneficiary);

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      if(err instanceof Yup.ValidationError) {
        handleError(err.message)
      } else {
        handleNotFoundBeneficiary()
      }
    }
  };

  return (
    <Grid container item lg={3} md={4} sm={6} xs={11} spacing={2} className={classes.cardLogin}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography className={classes.title18}>Bem Vindo!</Typography>
      </Grid>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography className={classes.title16}>Identificação através do CPF</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextFieldSimpleRegister
          name="cpf"
          label="CPF"
          placeholder="Digite seu CPF"
          value={cpf}
          onChange={(event: any) => setCpf(event.target.value)}
        />
      </Grid>
      <Grid item xs={12} className={classes.divButton}>
        <ButtonMain loading={loading} title="Consultar" onClick={onSubmit} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(FishConsult));